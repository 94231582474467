import React, {
  useState, useEffect, useContext, useCallback, useMemo,
} from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import PopinsModal from './PopinsModal';
import { getPopinsResultsQuery, popinsModalTextQuery } from '../../../gql/popinsOperations.gql';
import useGeocoder from '../../../hooks/useGeocoder';
import CheckoutPageContext from '../../../context/CheckoutPageContext';
import usePreferredStore from './usePreferredStore';
import $window from '../../../tools/window';
import trackAction from '../../../tools/analytics';

const id = 'popins-modal';
export default function PopinsModalContainer() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorState, setIsErrorState] = useState(false);
  const [isInputSearching, setIsInputSearching] = useState(false);
  const [isNearMeSearching, setIsNearMeSearching] = useState(false);
  const [areStoresFound, setAreStoresFound] = useState(false);
  const [popinsResults, setPopinsResults] = useState(undefined);
  const [popinsModalText, setPopinsModalText] = useState(undefined);
  const [defaultSearchValue, setDefaultSearchValue] = useState(undefined);
  const [updatedCountry, setUpdatedCountry] = useState('');
  const [isGeolocationBlocked, setIsGeolocationBlocked] = useState(false);
  const {
    checkoutPageState, selectedStore,
    setSelectedStore,
  } = useContext(CheckoutPageContext);
  const preferredStore = usePreferredStore();
  const geocoder = useGeocoder({ apiKey: checkoutPageState.googleApiKey });

  const {
    loading: textLoading,
    error: textError,
  } = useQuery(popinsModalTextQuery, {
    onCompleted: ({ textFor }) => {
      setPopinsModalText(textFor);
    },
    onError: () => {
      setIsErrorState(true);
    },
  });

  // track if this loading to avoid running multiple queries
  const [
    getPopinsResults, { loading: searchingForStores, error: popinsError },
  ] = useLazyQuery(getPopinsResultsQuery, {
    onCompleted: ({ popins }) => {
      let message = 'search complete - ';
      if (popins?.searchStores) {
        setPopinsResults(popins.searchStores);
        setIsInputSearching(false);
        setIsNearMeSearching(false);
        if (popins?.searchStores?.popinsLocations?.length > 0) {
          setAreStoresFound(true);
        }
        message += popins?.searchStores?.popinsLocations?.length > 0 ? 'results' : 'no results';
      } else {
        message += 'no results';
      }
      trackAction('analytics.checkoutInteraction', {
        type: 'popins',
        message,
      });
    },
    onError: () => {
      setIsInputSearching(false);
      setIsNearMeSearching(false);
      setIsErrorState(true);
      trackAction('analytics.errorTracking', {
        type: popinsModalText?.pickupInStore,
        message: popinsModalText?.popinsResultsError,
      });
    },
  });

  useEffect(() => {
    if (checkoutPageState?.shippingAddress?.country && updatedCountry === '') {
      setUpdatedCountry(checkoutPageState.shippingAddress?.country);
    }
  }, [checkoutPageState.shippingAddress, updatedCountry]);

  const bagItems = useMemo(
    () => (checkoutPageState?.bagItems?.items || []).map((product) => {
      const { shortSku, brand } = product.item.productContent;
      return {
        shortSku,
        brand,
      };
    }),
    [checkoutPageState.bagItems],
  );

  const searchForStores = useCallback(async (value, newCountry) => {
    // before searching clear the values and reset the error state
    setAreStoresFound(false);
    setPopinsResults(null);
    setIsErrorState(false);
    setIsGeolocationBlocked(false);
    let geolocation;
    try {
      if (!value) {
        geolocation = await geocoder.findMe();
      } else {
        geolocation = await geocoder.getAddressGeolocation(value);
      }

      getPopinsResults({
        variables: {
          geolocation,
          bagItems,
          shippingAddress: { country: newCountry || updatedCountry },
        },
      });
    } catch (error) {
      setIsInputSearching(false);
      setIsNearMeSearching(false);
      if (error instanceof $window.GeolocationPositionError || error.message === 'geoposition-error') {
        setIsGeolocationBlocked(true);
      }
    }
  }, [getPopinsResults, geocoder, updatedCountry, bagItems]);

  const handleInputSearch = useCallback((value) => {
    if (!value) {
      return;
    }
    setIsInputSearching(true);
    searchForStores(value);
  }, [searchForStores]);

  const handleNearMeSearch = useCallback(() => {
    setIsNearMeSearching(true);
    searchForStores();
  }, [searchForStores]);

  const onUpdateCountry = useCallback((value) => {
    setDefaultSearchValue(null);
    setAreStoresFound(false);
    setPopinsResults(null);
    setIsErrorState(false);
    setIsGeolocationBlocked(false);
    setUpdatedCountry(value);
  }, [setUpdatedCountry]);

  const handleOpenModal = useCallback((event) => {
    // near me search takes a higher priority
    if (event?.detail?.nearme) {
      // doing a near me search we don't want to reset the default search value
      setIsNearMeSearching(true);
      searchForStores();
    } else if (event?.detail?.comingFromInputSearch) {
      setIsInputSearching(true);
      setDefaultSearchValue(event.detail.value.searchInputValue);
      if (event.detail.value?.country) {
        setUpdatedCountry(event.detail.value.country);
      }
      searchForStores(event.detail.value.searchInputValue, event.detail?.value?.country);
    } else if (event?.detail?.value) {
      setIsInputSearching(true);
      setDefaultSearchValue(event.detail.value);
      searchForStores(event.detail.value);
    }
    setIsModalOpen(true);
  }, [searchForStores]);

  const handleCloseModal = useCallback((onlyClose) => {
    setIsModalOpen(false);
    // clear it out so that the next time the modal is opened it will search for the stores using
    // the preferred store if nothing was selected
    setDefaultSearchValue(null);
    if (onlyClose) {
      return;
    }
    if (selectedStore) {
      // respond back with the same store
      $window.dispatchEvent(new CustomEvent('popins:mfeStoreSelected', {
        detail: {
          selectedStore,
        },
      }));
    }
  }, [selectedStore]);

  const handleSetSelectedStore = useCallback((store) => {
    setIsModalOpen(false);
    setSelectedStore({ ...store });
    trackAction('analytics.checkCheckoutData', {
      message: store.itemAvailability?.orderAvailabilityTimestamp,
      type: 'storeAvailabilityDate',
    });
    $window.dispatchEvent(new CustomEvent('popins:mfeStoreSelected', {
      detail: {
        selectedStore: {
          ...store,
          latitude: store.geoPosition.latitude,
          longitude: store.geoPosition.longitude,
          addressLine: store.address.street,
          city: store.address.city,
          country: store.address.country,
          countryName: store.address.country,
          postalCode: store.address.postalCode,
          province: store.address.province,
          state: store.address.state,
          stateOrProvinceName: store.address.state,
          storeNumber: store.id,
          physicalStoreAttribute: store.physicalStoreAttribute,
          inventoryAvailability: store.inventoryAvailability,
          mapAndHoursUrl: store.mapAndHoursUrl,
          staticMapUrl: store.staticMapUrl,
        },
      },
    }));
  }, [setSelectedStore]);

  useEffect(() => {
    $window.addEventListener(`${id}:open`, handleOpenModal);
    return () => {
      $window.removeEventListener(`${id}:open`, handleOpenModal);
    };
  }, [handleOpenModal]);

  useEffect(() => {
    $window.addEventListener(`${id}:close`, handleCloseModal);
    return () => {
      $window.removeEventListener(`${id}:close`, handleCloseModal);
    };
  }, [handleCloseModal]);

  useEffect(() => {
    // if we're searching break out since we don't want to potentially start another search
    if (searchingForStores) {
      return;
    }
    if (!preferredStore) {
      return;
    }
    if (preferredStore.name && !selectedStore) {
      setSelectedStore(preferredStore);
      searchForStores(preferredStore.address.postalCode);
      return;
    }
    // if we reset the default search value with a near me search
    // two searches would be executed which would end up in a weird state
    if (preferredStore.address.postalCode === defaultSearchValue) {
      return;
    }
    // if we have default search value we don't want to search for stores using
    // the preferred store postal code since the default search value is set by the user
    if (defaultSearchValue) {
      return;
    }
    setDefaultSearchValue(preferredStore.address.postalCode);
  }, [
    preferredStore,
    defaultSearchValue,
    selectedStore,
    searchingForStores,
    setSelectedStore,
    searchForStores,
  ]);

  if (textLoading || textError) return null;

  return (
    <PopinsModal
      defaultSearchValue={defaultSearchValue}
      isErrorState={isErrorState}
      isGeolocationBlocked={isGeolocationBlocked}
      isOpen={isModalOpen}
      isInputSearching={isInputSearching}
      isNearMeSearching={isNearMeSearching}
      areStoresFound={areStoresFound}
      onClose={handleCloseModal}
      onInputSearch={handleInputSearch}
      onNearMeSearch={handleNearMeSearch}
      onSetSelectedStore={handleSetSelectedStore}
      popinsResults={popinsResults}
      searchApiErrorMessage={
        popinsError?.graphQLErrors?.[0]?.extensions?.response?.body?.error?.[0]?.errorMessage
      }
      selectedStore={selectedStore}
      text={popinsModalText}
      countryList={checkoutPageState?.shipOrPickup?.countryList}
      country={updatedCountry}
      onUpdateCountry={onUpdateCountry}
    />
  );
}
